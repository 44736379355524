<template>
  <div class="menu-container">
    <!-- 搜索区域 -->
    <div class="menu-search">
      <el-form
        :inline="true"
        :model="menu"
        ref="menu"
        class="demo-form-inline"
        size="small"
      >
        <el-form-item label="菜单名称" prop="menuName">
          <el-input
            v-model="menu.menuName"
            placeholder="请输入菜单名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-select v-model="menu.status" placeholder="菜单状态">
            <el-option label="正常" value="1"></el-option>
            <el-option label="停用" value="0"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="onSearchFrom"
            size="mini"
            >搜索</el-button
          >
          <el-button
            icon="el-icon-search"
            @click="resetForm('menu')"
            size="mini"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- 按钮区域 -->
    <div class="menu-btn">
      <el-button type="primary" plain size="mini" icon="el-icon-plus"
        >新增</el-button
      >
    </div>

    <!-- 表格区域 -->
    <el-table
      :data="menuList"
      style="width: 100%"
      :header-cell-style="headerRowStyle"
      v-loading="loading"
      row-key="menuId"
      :tree-props="{ children: 'subMenu', hasChildren: 'hasChildren' }"
    >
      <el-table-column
        prop="menuName"
        label="菜单名称"
        width="180"
      ></el-table-column>
      <el-table-column prop="sort" label="排序" width="180"></el-table-column>
      <el-table-column prop="menuUrl" label="路径"></el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.status === 1 ? 'primary' : 'success'"
            disable-transitions
            >{{ scope.row.status === 1 ? "正常" : "停用" }}</el-tag
          >
        </template>
      </el-table-column>

      <el-table-column
        prop="createTime"
        label="创建时间"
        :formatter="formatDate"
      ></el-table-column>

      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button
            @click="handleClick(scope.row)"
            type="text"
            size="small"
            icon="el-icon-edit"
            >修改</el-button
          >
          <el-button type="text" size="small" icon="el-icon-delete"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //表格表头样式
      headerRowStyle: {
        color: " #515A6E",
        backgroundColor: " #f8f8f9",
      },

      //---------搜索区域----------
      menu: {
        menuName: "",
        status: "",
      },
      //---------增删改按钮区域---------

      //---------表格区域----------
      // 加载样式
      loading: false,
      // 菜单数据列表
      menuList: [],
    };
  },

  created() {
    this.getMenuPageList();
  },
  methods: {
    // --------------------------搜索区域--------------------------
    //搜索菜单列表
    async onSearchFrom() {
      this.loading = true;
      const { data: res } = await this.$axios.post(
        `${this.$http}/menu/search`,
        this.menu
      );
      //设置加载效果定时器
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      this.menuList = res.data;
    },
    //重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    // -----------------------按钮栏区域-----------------------

    // -----------------------表格区域-----------------------
    //日期格式化
    formatDate(row, column, cellValue) {
      const date = new Date(cellValue);
      function padZero(num) {
        return num < 10 ? "0" + num : num;
      }
      const year = date.getFullYear();
      const month = padZero(date.getMonth() + 1);
      const day = padZero(date.getDate());
      const hours = padZero(date.getHours());
      const minutes = padZero(date.getMinutes());
      const seconds = padZero(date.getSeconds());
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    // 获取菜单列表
    async getMenuPageList() {
      this.loading = true;
      const { data: res } = await this.$axios.post(`${this.$http}/menu/list`);
      console.log(res);
      //设置加载效果定时器
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      this.menuList = res.data;
    },

    // handleClick(row) {
    //   console.log(row);
    // },
  },
};
</script>

<style lang="less" scoped>
.el-form-item {
  font-weight: 700;
  color: #606266;
}
.menu-search .el-input,
.menu-search .el-select {
  width: 240px;
}
.menu-btn {
  margin-bottom: 8px;
}
.el-pagination {
  margin-top: 15px;
}
</style>