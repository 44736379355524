<template>
  <div class="merchant-container">
    <!-- 搜索区域 -->
    <div class="merchant-search">
      <el-form
        :inline="true"
        :model="merchant"
        ref="merchant"
        class="demo-form-inline"
        size="small"
      >
        <el-form-item label="商家名称" prop="businessName">
          <el-input
            v-model.trim="merchant.businessName"
            placeholder="请输入商家名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="地址" prop="businessAddress">
          <el-input
            v-model.trim="merchant.businessAddress"
            placeholder="请输入商家地址"
          ></el-input>
        </el-form-item>

        <el-form-item label="联系电话" prop="contactPhoneNumber">
          <el-input
            v-model.trim="merchant.contactPhoneNumber"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item label="创建时间" prop="dateList">
          <el-date-picker
            v-model.trim="merchant.dateList"
            type="daterange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 240px"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="onSearchFrom"
            >搜索</el-button
          >
          <el-button
            icon="el-icon-search"
            size="mini"
            @click="resetForm('merchant')"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- 表格区域 -->
    <el-table
      :data="merchantList"
      style="width: 100%"
      :header-cell-style="headerRowStyle"
      v-loading="loading"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="merchantId"
        label="商家编号"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="businessName"
        label="商家名称"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="businessAddress"
        label="商家地址"
      ></el-table-column>
      <el-table-column
        prop="contactPhoneNumber"
        label="联系电话"
      ></el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.isActive == 1">已认证</el-tag>
          <el-tag type="danger" size="small" v-else-if="scope.row.isActive == 0"
            >待审核</el-tag
          >
          <el-tag
            type="warning"
            size="small"
            v-else-if="scope.row.isActive == 2"
            >审核未通过</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        :formatter="formatDate"
      ></el-table-column>

      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button
            @click="handleDetailsClick(scope.row)"
            type="text"
            size="small"
            icon="el-icon-edit"
            >审核通过</el-button
          >
          <el-button
            @click="handleDetailsClick2(scope.row)"
            type="text"
            size="small"
            icon="el-icon-edit"
            >审核不通过</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页区域 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pages.current"
      :page-sizes="[3, 5, 10]"
      :page-size="5"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pages.total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //表格表头样式
      headerRowStyle: {
        color: " #515A6E",
        backgroundColor: " #f8f8f9",
      },

      //----------搜索区域-----------
      //搜索数据源
      merchant: {
        businessName: "",
        businessAddress: "",
        contactPhoneNumber: "",
        dateList: [],
      },

      //---------增删改按钮区域---------
      //修改按钮禁用状态
      updateDisabled: true,
      //删除按钮禁用状态
      deleteDisabled: true,
      //新增弹窗
      addDialogVisible: false,
      //修改弹窗
      updateDialogVisible: false,
      //新增数据源
      newUser: {
        username: "",
        phone: "",
        status: 1,
      },
      //非空校验
      rules: {
        username: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
      },
      // ----------表格区域------------
      //列表加载效果
      loading: false,
      //数据列表
      merchantList: [],
      //多选框数据列表
      multipleSelection: [],

      //----------分页区域------------
      pages: {
        current: 1, //当前页
        size: 5, //每页记录数
      },
    };
  },
  created() {
    this.getMerchantPageList();
  },
  methods: {
    // -----------------------搜索区域-----------------------
    //搜索查询用户列表
    async onSearchFrom() {
      //将数据封装为一个对象
      const requestData = {
        merchant: this.merchant,
        pageUtils: this.pages,
      };
      this.loading = true;
      const { data: res } = await this.$axios.post(
        `${this.$http}/merchant/search`,
        requestData
      );
      //设置加载效果定时器
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      this.merchantList = res.data.merchantList;
      this.pages = res.data.pageUtils;
    },
    //重置搜索
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    // -----------------------表格区域-----------------------
    //日期格式化
    formatDate(row, column, cellValue) {
      const date = new Date(cellValue);
      function padZero(num) {
        return num < 10 ? "0" + num : num;
      }
      const year = date.getFullYear();
      const month = padZero(date.getMonth() + 1);
      const day = padZero(date.getDate());
      const hours = padZero(date.getHours());
      const minutes = padZero(date.getMinutes());
      const seconds = padZero(date.getSeconds());
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    //获取用户列表
    async getMerchantPageList() {
      this.loading = true;
      const { data: res } = await this.$axios.post(
        `${this.$http}/merchant/list`,
        this.pages
      );
      //设置加载效果定时器
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      this.merchantList = res.data.merchantList;
      this.pages = res.data.pageUtils;
    },
    //多选操作
    handleSelectionChange(val) {
      this.updateDisabled = val.length !== 1;
      this.deleteDisabled = val.length === 0;
      this.multipleSelection = val;
    },
    //查看详情
    async handleDetailsClick(row) {
      this.$confirm(`是否通过审核`, "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          row.isActive = 1;
          this.tongguo(row);
          this.getMerchantPageList();
        })
        .catch(() => {
          console.log("取消删除");
        });

      // this.$router.push({
      //   path: "/home/detailsPage",
      //   query: { id: row.merchantId },
      // });
    },

    async tongguo(row) {
      const { data: res } = await this.$axios.post(
        `${this.$http}/merchant/confirm`,
        row
      );
    },

    //审核未通过
    async handleDetailsClick2(row) {
      this.$confirm(`是否通过审核`, "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          row.isActive = 2;
          this.tongguo(row);
          this.getMerchantPageList();
        })
        .catch(() => {
          console.log("取消删除");
        });
    },

    // -----------------------分页区域-----------------------
    //切换记录数
    handleSizeChange(val) {
      this.pages.size = val;
      // 检查 productName、price 和 status 是否为空字符串
      const isEmptyString = (str) => str === "";
      const hasNonEmptyString =
        !isEmptyString(this.merchant.businessName) ||
        !isEmptyString(this.merchant.businessAddress) ||
        !isEmptyString(this.merchant.contactPhoneNumber);
      // 检查 dateList 数组是否为空
      const hasNonEmptyArray =
        Array.isArray(this.merchant.dateList) &&
        this.merchant.dateList.length > 0;
      // 根据检查结果返回对应的值
      if (!hasNonEmptyString && !hasNonEmptyArray) {
        // 没有数据
        this.getMerchantPageList();
      } else {
        // 至少有一个属性有值
        this.onSearchFrom();
      }
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pages.current = val;
      // 检查 productName、price 和 status 是否为空字符串
      const isEmptyString = (str) => str === "";
      const hasNonEmptyString =
        !isEmptyString(this.merchant.businessName) ||
        !isEmptyString(this.merchant.businessAddress) ||
        !isEmptyString(this.merchant.contactPhoneNumber);
      // 检查 dateList 数组是否为空
      const hasNonEmptyArray =
        Array.isArray(this.merchant.dateList) &&
        this.merchant.dateList.length > 0;
      // 根据检查结果返回对应的值
      if (!hasNonEmptyString && !hasNonEmptyArray) {
        // 没有数据
        this.getMerchantPageList();
      } else {
        // 至少有一个属性有值
        this.onSearchFrom();
      }
    },
  },
};
</script>

<style lang="less" scoped>
// -------搜索区域---------
.el-form-item {
  font-weight: 700;
  color: #606266;
}
.merchant-search .el-input,
.merchant-search .el-select {
  width: 240px;
}
//--------按钮区域--------
.merchant-btn {
  margin-bottom: 8px;
}
//-------分页区域--------
.el-pagination {
  margin-top: 15px;
}
</style>