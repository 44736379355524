<template>
  <div class="login-container">
    <div class="header">登录</div>
    <el-form
      :model="user"
      status-icon
      :rules="rules"
      ref="user"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="账号" prop="username">
        <el-input v-model="user.username" placeholder="请输入账号"></el-input>
      </el-form-item>

      <el-form-item label="密码" prop="password">
        <el-input
          type="password"
          v-model="user.password"
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>

      <!-- <el-form-item
        label="验证码"
        prop="captchaCode"
        style="width: 225px"
        class="el-form-item-captcha"
      >
        <el-input v-model="captchaCode" placeholder="验证码"></el-input>
        <img :src="captchaImage" class="captcha-image" @click="getCaptcha" />
      </el-form-item> -->

      <el-form-item>
        <router-link to="loginMain/register">注册</router-link>
        <el-button
          @click="submitForm('user')"
          class="btn-login"
          :loading="loading"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
    
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      user: {
        username: "admin",
        password: "123456",
      },
      loading: false,
      //验证码
      captchaCode: "",
      //验证码图片
      captchaImage: "",
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  created() {
    // this.getCaptcha();
  },
  methods: {
    ...mapMutations(["setUsername"]),
    // 登录
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 验证成功，提交表单
          //验证验证码
          // if (this.captchaCode != "") {
          //   this.loading = true;
          //   this.validateCaptcha();
          // }
          this.login();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    //登录认证
    login() {
      this.$axios.post(`${this.$http}/login/login`, this.user).then((res) => {
        if (res.data.code == 200) {
          // 设置token
          this.setUsername(res.data.data.user.username);
          localStorage.setItem("token", res.data.data.token);
          //登录成功跳转首页
          setTimeout(() => {
            this.$router.push("/home");
            //登录成功弹窗
            this.$message({
              message: res.data.message,
              type: "success",
            });
          }, 1500);
        } else {
          setTimeout(() => {
            this.loading = false;
            this.$message.error(res.data.message);
          }, 500);
        }
      });
    },

    //验证码校验
    async validateCaptcha() {
      const headers = { "Content-Type": "application/json" };
      const { data: res } = await this.$axios.post(
        `${this.$http}/login/checkCode`,
        this.captchaCode,
        headers
      );
      if (res.code === 200) {
        this.login();
      } else {
        this.loading = false;
        this.$message.error(res.message);
        //验证码错误再次刷新
        setTimeout(() => {
          this.getCaptcha();
        }, 500);
      }
    },

    //获取验证码图片
    async getCaptcha() {
      const { data: res } = await this.$axios.get(`${this.$http}/login/code`);
      this.captchaImage = res.data;
    },
  },
};
</script>

<style scoped lang="less">
.header {
  font-size: 38px;
  font-weight: bold;
  text-align: center;
  line-height: 150px;
}
.el-form {
  margin-top: -10px;
  padding: 20px;
}
.el-form-item {
  position: relative;
  margin-left: -55px;
}
.el-input {
  // border-bottom: 1px solid rgb(128, 125, 125);
  // display: block;
  width: 100%;
  // margin-bottom: 10px;
  // border: 0;
  // outline: none;
}
.el-form-item-captcha {
  position: relative;
}
.captcha-image {
  border: 1px solid #7ea079;
  position: absolute;
  width: 120px;
  top: 4.5px;
  margin: 0 20px;
}
a {
  position: absolute;
  text-decoration-line: none;
  color: #abc1ee;
  right: 0;
  top: -25px;
}
.btn-login {
  background-image: linear-gradient(to right, #a6c1ee, #fbc2eb);
  color: #fff;
  margin-top: 15px;
  width: 100%;
  padding: 10px;
}
</style>