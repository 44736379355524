<template>
  <div class="product-container">
    <!-- 搜索区域 -->
    <div class="product-search">
      <el-form
        :inline="true"
        :model="product"
        ref="product"
        class="demo-form-inline"
        size="small"
      >
        <el-form-item label="商品名称" prop="productName">
          <el-input
            v-model.trim="product.productName"
            placeholder="请输入商品名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="价格" prop="startPrice">
          <el-input
            v-model.trim="product.startPrice"
            placeholder="起始价格"
            style="width: 120px"
          ></el-input>
        </el-form-item>

        <el-form-item prop="endPrice">
          <el-input
            v-model.trim="product.endPrice"
            placeholder="结束价格"
            style="width: 120px"
          ></el-input>
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-select v-model.trim="product.status" placeholder="商品状态">
            <el-option label="已审核" value="1"></el-option>
            <el-option label="待审核" value="0"></el-option>
          </el-select>
        </el-form-item>
        <br />
        <el-form-item label="创建时间" prop="dateList">
          <el-date-picker
            v-model.trim="product.dateList"
            type="daterange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 240px"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="onSearchFrom"
            >搜索</el-button
          >
          <el-button
            icon="el-icon-search"
            size="mini"
            @click="resetForm('product')"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- 按钮区域 -->
    <!-- <div class="product-btn">
      <el-button
        type="primary"
        plain
        size="mini"
        icon="el-icon-plus"
        @click="addDialogVisible = true"
        >新增</el-button
      >
      <el-button
        type="success"
        plain
        size="mini"
        icon="el-icon-edit"
        :disabled="updateDisabled"
        @click="handleupdateDialogVisible"
        >修改</el-button
      >
      <el-button
        type="danger"
        plain
        size="mini"
        icon="el-icon-delete"
        :disabled="deleteDisabled"
        @click="handleBatchDeletion"
        >删除</el-button
      >
    </div> -->

    <!-- 表格区域 -->
    <el-table
      :data="productList"
      style="width: 100%"
      :header-cell-style="headerRowStyle"
      v-loading="loading"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="productId"
        label="商品编号"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="productName"
        label="商品名称"
        width="180"
      ></el-table-column>
      <el-table-column prop="image" label="商品图片"></el-table-column>
      <el-table-column prop="price" label="商品价格"></el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.status == 1">已审核</el-tag>
          <el-tag type="danger" size="small" v-else>待审核</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        :formatter="formatDate"
      ></el-table-column>

      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button
            @click="handleUpdateClick(scope.row)"
            type="text"
            size="small"
            icon="el-icon-edit"
            v-if="scope.row.status == 0"
            >审核</el-button
          >
          <!-- <el-button
            type="text"
            size="small"
            icon="el-icon-delete"
            @click="handleDeleteClick(scope.row)"
            >删除</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页区域 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pages.current"
      :page-sizes="[3, 5, 10]"
      :page-size="5"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pages.total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //表格表头样式
      headerRowStyle: {
        color: " #515A6E",
        backgroundColor: " #f8f8f9",
      },

      //----------搜索区域-----------
      //搜索数据源
      product: {
        productName: "",
        startPrice: "",
        endPrice: "",
        status: "",
        dateList: [],
      },

      //---------增删改按钮区域---------
      //修改按钮禁用状态
      updateDisabled: true,
      //删除按钮禁用状态
      deleteDisabled: true,
      //新增弹窗
      addDialogVisible: false,
      //修改弹窗
      updateDialogVisible: false,
      //新增数据源
      newUser: {
        username: "",
        phone: "",
        status: 1,
      },
      //非空校验
      rules: {
        username: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
      },
      // ----------表格区域------------
      //列表加载效果
      loading: false,
      //数据列表
      productList: [],
      //多选框数据列表
      multipleSelection: [],

      //----------分页区域------------
      pages: {
        current: 1, //当前页
        size: 5, //每页记录数
      },
    };
  },
  created() {
    this.getProductPageList();
  },
  methods: {
    // -----------------------搜索区域-----------------------
    //搜索查询列表
    async onSearchFrom() {
      //将数据封装为一个对象
      const requestData = {
        product: this.product,
        pageUtils: this.pages,
      };
      this.loading = true;
      const { data: res } = await this.$axios.post(
        `${this.$http}/product/search`,
        requestData
      );
      //设置加载效果定时器
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      this.productList = res.data.productList;
      this.pages = res.data.pageUtils;
    },
    //重置搜索
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    // -----------------------表格区域-----------------------
    //日期格式化
    formatDate(row, column, cellValue) {
      const date = new Date(cellValue);
      function padZero(num) {
        return num < 10 ? "0" + num : num;
      }
      const year = date.getFullYear();
      const month = padZero(date.getMonth() + 1);
      const day = padZero(date.getDate());
      const hours = padZero(date.getHours());
      const minutes = padZero(date.getMinutes());
      const seconds = padZero(date.getSeconds());
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    //获取用户列表
    async getProductPageList() {
      this.loading = true;
      const { data: res } = await this.$axios.post(
        `${this.$http}/product/list`,
        this.pages
      );
      //设置加载效果定时器
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      this.productList = res.data.productList;
      this.pages = res.data.pageUtils;
    },
    //多选操作
    handleSelectionChange(val) {
      console.log(1);
      this.updateDisabled = val.length !== 1;
      this.deleteDisabled = val.length === 0;
      this.multipleSelection = val;
    },
    //修改点击弹窗操作
    handleUpdateClick(row) {
      // this.updateDialogVisible = true;
      // const tempUser = Object.assign({}, row); // 复制当前行数据，创建一个新的对象
      // this.newUser = tempUser;
    },
    //删除点击操作
    handleDeleteClick(row) {
      // this.$confirm(`是否删除用户编号为"${row.userId}"的用户？`, "系统提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(() => {
      //     this.individualDeletion(row);
      //   })
      //   .catch(() => {
      //     console.log("取消删除");
      //   });
    },
    // -----------------------分页区域-----------------------
    //切换记录数
    handleSizeChange(val) {
      console.log(this.product);
      this.pages.size = val;
      // 检查 productName、price 和 status 是否为空字符串
      const isEmptyString = (str) => str === "";
      const hasNonEmptyString =
        !isEmptyString(this.product.productName) ||
        !isEmptyString(this.product.price) ||
        !isEmptyString(this.product.status);
        console.log(hasNonEmptyString);
      // 检查 dateList 数组是否为空
      const hasNonEmptyArray =
        Array.isArray(this.product.dateList) &&
        this.product.dateList.length > 0;
      // 根据检查结果返回对应的值
      if (!hasNonEmptyString && !hasNonEmptyArray) {
        console.log(2);
        // 没有数据
        this.getProductPageList();
      } else {
        console.log(1);
        // 至少有一个属性有值
        this.onSearchFrom();
      }
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pages.current = val;
      // 检查 productName、price 和 status 是否为空字符串
      const isEmptyString = (str) => str === "";
      const hasNonEmptyString =
        !isEmptyString(this.product.productName) ||
        !isEmptyString(this.product.price) ||
        !isEmptyString(this.product.status);
      // 检查 dateList 数组是否为空
      const hasNonEmptyArray =
        Array.isArray(this.product.dateList) &&
        this.product.dateList.length > 0;
      // 根据检查结果返回对应的值
      if (!hasNonEmptyString && !hasNonEmptyArray) {
        // 没有数据
        this.getProductPageList();
      } else {
        // 至少有一个属性有值
        this.onSearchFrom();
      }
    },
  },
};
</script>

<style lang="less" scoped>
// -------搜索区域---------
.el-form-item {
  font-weight: 700;
  color: #606266;
}
.product-search .el-input,
.product-search .el-select {
  width: 240px;
}
//--------按钮区域--------
.product-btn {
  margin-bottom: 8px;
}
//-------分页区域--------
.el-pagination {
  margin-top: 15px;
}
</style>