<template>
  <div class="details-container">详情页面</div>
</template>

<script>
export default {
  data() {
    return {
      merchant: {},
      merchantId: "",
    };
  },
  created() {
    const id = this.$route.query.id;
    this.getMerchant(id);
  },
  methods: {
    //获取对象
    async getMerchant(merchantId) {
      const { data: res } = await this.$axios.get(
        `${this.$http}/merchant/getById`,
        {
          params: { merchantId },
        }
      );
      this.merchant = res.data;
      console.log(this.merchant);
    },
  },
};
</script>

<style>
</style>