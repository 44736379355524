<template>
  <div class="role-container">
    <!-- 搜索区域 -->
    <div class="role-search">
      <el-form
        :inline="true"
        :model="role"
        ref="role"
        class="demo-form-inline"
        size="small"
      >
        <el-form-item label="角色名称" prop="roleName">
          <el-input
            v-model.trim="role.roleName"
            placeholder="请输入角色名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-select v-model.trim="role.status" placeholder="角色状态">
            <el-option label="正常" value="1"></el-option>
            <el-option label="停用" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间" prop="dateList">
          <el-date-picker
            v-model.trim="role.dateList"
            type="daterange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 240px"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="onSearchFrom"
            >搜索</el-button
          >
          <el-button
            icon="el-icon-search"
            size="mini"
            @click="resetForm('role')"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- 按钮区域 -->
    <div class="role-btn">
      <el-button
        type="primary"
        plain
        size="mini"
        icon="el-icon-plus"
        @click="addDialogVisible = true"
        >新增</el-button
      >
      <el-button
        type="success"
        plain
        size="mini"
        icon="el-icon-edit"
        :disabled="updateDisabled"
        >修改</el-button
      >
      <el-button
        type="danger"
        plain
        size="mini"
        icon="el-icon-delete"
        :disabled="deleteDisabled"
        >删除</el-button
      >
    </div>

    <!-- 表格区域 -->
    <el-table
      :data="roleList"
      style="width: 100%"
      :header-cell-style="headerRowStyle"
      v-loading="loading"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="roleId"
        label="角色编号"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="roleName"
        label="角色名称"
        width="180"
      ></el-table-column>

      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.status"
            :active-value="1"
            :inactive-value="0"
            @change="handleSwitchChange($event, scope.row)"
          >
          </el-switch> </template
      ></el-table-column>

      <el-table-column
        prop="createTime"
        label="创建时间"
        :formatter="formatDate"
      ></el-table-column>

      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button
            @click="handleClick(scope.row)"
            type="text"
            size="small"
            icon="el-icon-edit"
            >修改</el-button
          >
          <el-button type="text" size="small" icon="el-icon-delete"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页区域 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pages.current"
      :page-sizes="[3, 5, 10]"
      :page-size="5"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pages.total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //表格表头样式
      headerRowStyle: {
        color: " #515A6E",
        backgroundColor: " #f8f8f9",
      },

      //----------搜索区域-----------
      //搜索用户数据源
      role: {
        roleName: "",
        status: "",
        dateList: [],
      },

      //---------增删改按钮区域---------
      //修改按钮禁用状态
      updateDisabled: true,
      //删除按钮禁用状态
      deleteDisabled: true,

      // ----------表格区域------------
      //列表加载效果
      loading: false,
      //用户数据列表
      roleList: [],
      //多选框数据列表
      multipleSelection: [],

      //----------分页区域------------
      pages: {
        current: 1, //当前页
        size: 5, //每页记录数
      },
    };
  },

  created() {
    this.getRolePageList();
  },

  methods: {
    // -----------------------搜索区域-----------------------
    //搜索查询用户列表
    async onSearchFrom() {
      //将数据封装为一个对象
      const requestData = {
        role: this.role,
        pageUtils: this.pages,
      };
      this.loading = true;
      const { data: res } = await this.$axios.post(
        `${this.$http}/role/search`,
        requestData
      );
      //设置加载效果定时器
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      this.roleList = res.data.roleList;
    },

    //重置搜索
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    // -----------------------表格区域-----------------------
    //日期格式化
    formatDate(row, column, cellValue) {
      const date = new Date(cellValue);
      function padZero(num) {
        return num < 10 ? "0" + num : num;
      }
      const year = date.getFullYear();
      const month = padZero(date.getMonth() + 1);
      const day = padZero(date.getDate());
      const hours = padZero(date.getHours());
      const minutes = padZero(date.getMinutes());
      const seconds = padZero(date.getSeconds());
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    //获取角色列表
    async getRolePageList() {
      this.loading = true;
      const { data: res } = await this.$axios.post(
        `${this.$http}/role/list`,
        this.pages
      );
      //设置加载效果定时器
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      this.roleList = res.data.roleList;
      this.pages = res.data.pageUtils;
    },
    //多选操作
    handleSelectionChange(val) {
      this.updateDisabled = val.length !== 1;
      this.deleteDisabled = val.length === 0;
      this.multipleSelection = val;
      // console.log(this.multipleSelection);
    },
    //切换状态效果
    handleSwitchChange(val, row) {
      this.$confirm(`确认要停用"${row.roleName}"用户吗?`, "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.updateRoleStatus(row);
          this.$message({
            type: "success",
            message: "修改成功!",
          });
        })
        .catch(() => {
          if (row.status == 1) {
            row.status = 0;
          } else {
            row.status = 1;
          }
        });
    },
    //修改用户状态
    async updateRoleStatus(role) {
      const { data: res } = await this.$axios.post(
        `${this.$http}/role/updateStatus`,
        role
      );
    },

    // -----------------------分页区域-----------------------
    //切换记录数
    handleSizeChange(val) {
      this.pages.size = val;
      this.getRolePageList();
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pages.current = val;
      this.getRolePageList();
    },
  },
};
</script>

<style lang="less" scoped>
// -------搜索区域---------
.el-form-item {
  font-weight: 700;
  color: #606266;
}
.role-search .el-input,
.role-search .el-select {
  width: 240px;
}
//--------按钮区域--------
.role-btn {
  margin-bottom: 8px;
}
//-------分页区域--------
.el-pagination {
  margin-top: 15px;
}

.add-dialog .el-input {
  width: 200px;
}
</style>