<template>
  <div class="home-container">
    <el-container>
      <!-- Aside -->
      <el-aside width="239px">
        <Aside></Aside>
      </el-aside>
      <!-- header -->
      <el-container>
        <el-header>
          <Header></Header>
        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Aside from "@/components/Aside.vue";
import Header from "@/components/Header.vue";
export default {
  components: {
    Aside,
    Header,
  },
};
</script>

<style lang="less" scoped>
.home-container {
  margin: -8px;
  width: 100%;
  height: 100vh;
  background-color: #F3F3F4;
  position: fixed;
}
.el-header {
  box-sizing: border-box;
  border: 1px solid #fafafa;
  background-color: #ffffff;
}

.el-main {
  position: relative;
  margin: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #ebebeb;
}
</style>