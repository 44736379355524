<template>
  <div class="merchant-sort-container">
    <!-- 搜索区域 -->
    <div class="merchant-sort-search">
      <el-form
        :inline="true"
        :model="merchantSort"
        ref="merchantSort"
        class="demo-form-inline"
        size="small"
      >
        <el-form-item label="分类名称" prop="sortName">
          <el-input
            v-model="merchantSort.sortName"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="onSearchFrom"
            size="mini"
            >搜索</el-button
          >
          <el-button
            icon="el-icon-search"
            @click="resetForm('merchantSort')"
            size="mini"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- 按钮区域 -->
    <div class="merchant-sort-btn">
      <el-button
        type="primary"
        plain
        size="mini"
        icon="el-icon-plus"
        @click="addDialogVisible = true"
        >新增</el-button
      >
    </div>

    <!-- 新增弹窗内容 -->
    <el-dialog
      title="添加分类"
      append-to-body
      width="38%"
      :visible.sync="addDialogVisible"
      :before-close="addHandleClose"
      class="add-dialog"
    >
      <el-form
        :inline="true"
        label-width="80px"
        :model="newMerchantSort"
        :rules="rules"
        ref="newMerchantSort"
        size="small"
        class="demo-form-inline"
      >
        <el-form-item label="分类名称" prop="sortName">
          <el-input
            v-model.trim="newMerchantSort.sortName"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="排序" prop="sort">
          <template>
            <el-input-number
              v-model="newMerchantSort.sort"
              controls-position="right"
              size="small"
            ></el-input-number>
          </template>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="add('newMerchantSort')"
          >确 定</el-button
        >
        <el-button @click="addCancel">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 修改弹窗内容 -->
    <el-dialog
      title="修改分类"
      append-to-body
      width="38%"
      :visible.sync="updateDialogVisible"
      :before-close="updateHandleClose"
      class="add-dialog"
    >
      <el-form
        :inline="true"
        label-width="80px"
        :model="newMerchantSort"
        :rules="rules"
        ref="newMerchantSort"
        size="small"
        class="demo-form-inline"
      >
        <el-form-item label="分类名称" prop="sortName">
          <el-input
            v-model.trim="newMerchantSort.sortName"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="排序" prop="sort">
          <template>
            <el-input-number
              v-model="newMerchantSort.sort"
              controls-position="right"
              size="small"
            ></el-input-number>
          </template>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="update('newMerchantSort')"
          >确 定</el-button
        >
        <el-button @click="updateCancel">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 表格区域 -->
    <el-table
      :data="merchantSortList"
      style="width: 100%"
      :header-cell-style="headerRowStyle"
      v-loading="loading"
      row-key="sortId"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="sortName" label="分类名称"></el-table-column>
      <el-table-column prop="sort" label="排序"></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        :formatter="formatDate"
      ></el-table-column>

      <el-table-column fixed="right" label="操作" width="190">
        <template slot-scope="scope">
          <el-button
            @click="handleUpdateClick(scope.row)"
            type="text"
            size="small"
            icon="el-icon-edit"
            >修改</el-button
          >
          <el-button
            type="text"
            size="small"
            icon="el-icon-delete"
            @click="handleDeleteClick(scope.row)"
            >删除</el-button
          >
          <el-button
            @click="handleAddClick(scope.row)"
            type="text"
            size="small"
            icon="el-icon-plus"
            v-if="scope.row.parentId == null"
            >新增</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 单行新增弹窗内容 -->
    <el-dialog
      title="添加分类"
      append-to-body
      width="38%"
      :visible.sync="addRowDialogVisible"
      :before-close="addHandleClose"
      class="add-dialog"
    >
      <el-form
        :inline="true"
        label-width="80px"
        :model="newMerchantSort"
        :rules="rules"
        ref="newMerchantSort"
        size="small"
        class="demo-form-inline"
      >
        <el-form-item label="分类名称" prop="sortName">
          <el-input
            v-model.trim="newMerchantSort.sortName"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="排序" prop="sort">
          <template>
            <el-input-number
              v-model="newMerchantSort.sort"
              controls-position="right"
              size="small"
            ></el-input-number>
          </template>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="add('newMerchantSort')"
          >确 定</el-button
        >
        <el-button @click="addCancel">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 分页区域 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pages.current"
      :page-sizes="[3, 5, 10]"
      :page-size="5"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pages.total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //表格表头样式
      headerRowStyle: {
        color: " #515A6E",
        backgroundColor: " #f8f8f9",
      },

      //----------搜索区域-----------
      //搜索数据源
      merchantSort: {
        sortName: "",
      },

      //---------增删改按钮区域---------
      //新增弹窗
      addDialogVisible: false,
      //修改弹窗
      updateDialogVisible: false,
      //新增数据源
      newMerchantSort: {
        sortName: "",
        sort: undefined,
        parentId: "",
      },
      //非空校验
      rules: {
        sortName: [
          { required: true, message: "分类名称不能为空", trigger: "blur" },
        ],
        sort: [{ required: true, message: "排序不能为空", trigger: "blur" }],
      },
      // ----------表格区域------------
      //新增弹窗
      addRowDialogVisible: false,
      //列表加载效果
      loading: false,
      //数据列表
      merchantSortList: [],
      //多选框数据列表
      multipleSelection: [],

      //----------分页区域------------
      pages: {
        current: 1, //当前页
        size: 5, //每页记录数
      },
    };
  },

  created() {
    this.getPageList();
  },
  methods: {
    // --------------------------搜索区域--------------------------
    //搜索查询列表
    async onSearchFrom() {
      //   //将数据封装为一个对象
      const requestData = {
        merchantSort: this.merchantSort,
        pageUtils: this.pages,
      };
      this.loading = true;
      const { data: res } = await this.$axios.post(
        `${this.$http}/merchantSort/search`,
        requestData
      );
      //设置加载效果定时器
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      this.merchantSortList = res.data.merchantSortList;
      this.pages = res.data.pageUtils;
    },
    //重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    // -----------------------按钮区域-----------------------
    //新增弹窗确认按钮点击
    add(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 验证成功，提交表单
          this.addData();
          if (this.$refs["newMerchantSort"]) {
            this.$refs.newMerchantSort.resetFields();
            this.newMerchantSort = {};
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //新增弹窗取消按钮点击
    addCancel() {
      this.addRowDialogVisible = false;
      this.addDialogVisible = false;
      if (this.$refs["newMerchantSort"]) {
        this.$refs.newMerchantSort.resetFields();
        this.newMerchantSort = {};
      }
    },
    //新增点击右上角x
    addHandleClose(done) {
      this.addRowDialogVisible = false;
      this.addDialogVisible = false;
      if (this.$refs["newMerchantSort"]) {
        this.$refs.newMerchantSort.resetFields();
        this.newMerchantSort = {};
      }
    },
    //修改弹窗确认按钮点击
    update(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 验证成功，提交表单
          this.updateData();
          if (this.$refs["newMerchantSort"]) {
            this.$refs.newMerchantSort.resetFields();
            this.newMerchantSort = {};
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //修改弹窗取消按钮点击
    updateCancel() {
      this.updateDialogVisible = false;
      if (this.$refs["newMerchantSort"]) {
        this.$refs.newMerchantSort.resetFields();
        this.newMerchantSort = {};
      }
    },
    //修改点击右上角x
    updateHandleClose(done) {
      this.updateDialogVisible = false;
      if (this.$refs["newMerchantSort"]) {
        this.$refs.newMerchantSort.resetFields();
        this.newMerchantSort = {};
      }
    },

    // -----------------------增删改区域-----------------------
    //新增数据
    async addData() {
      const { data: res } = await this.$axios.post(
        `${this.$http}/merchantSort/add`,
        this.newMerchantSort
      );
      if (res.data) {
        //添加成功
        this.$message({
          message: res.message,
          type: "success",
        });
        //添加成功关闭弹窗
        this.addDialogVisible = false;
        this.addRowDialogVisible = false;
        //添加成功刷新表格
        this.getPageList();
      } else {
        this.$message.error(res.message);
      }
    },
    //修改数据
    async updateData() {
      const { data: res } = await this.$axios.post(
        `${this.$http}/merchantSort/update`,
        this.newMerchantSort
      );
      console.log(res);
      if (res.data) {
        //修改成功
        this.$message({
          message: res.message,
          type: "success",
        });
        //修改成功关闭弹窗
        this.updateDialogVisible = false;
        //修改成功刷新表格
        this.getPageList();
      } else {
        this.$message.error(res.message);
      }
    },
    //单行删除
    async delete(row) {
      const { data: res } = await this.$axios.post(
        `${this.$http}/merchantSort/delete`,
        row
      );
      if (res.data) {
        this.$message({
          message: res.message,
          type: "success",
        });
        this.getPageList();
      } else {
        this.$message({
          message: res.message,
          type: "warning",
        });
      }
    },
    
    // -----------------------表格区域-----------------------
    //日期格式化
    formatDate(row, column, cellValue) {
      const date = new Date(cellValue);
      function padZero(num) {
        return num < 10 ? "0" + num : num;
      }
      const year = date.getFullYear();
      const month = padZero(date.getMonth() + 1);
      const day = padZero(date.getDate());
      const hours = padZero(date.getHours());
      const minutes = padZero(date.getMinutes());
      const seconds = padZero(date.getSeconds());
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    //获取表格列表
    async getPageList() {
      this.loading = true;
      const { data: res } = await this.$axios.post(
        `${this.$http}/merchantSort/list`,
        this.pages
      );
      //设置加载效果定时器
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      this.merchantSortList = res.data.merchantSortList;
      this.pages = res.data.pageUtils;
    },
    //添加点击操作
    handleAddClick(row) {
      this.addRowDialogVisible = true;
      this.newMerchantSort.parentId = row.sortId;
      console.log(this.newMerchantSort);
    },
    //修改点击弹窗操作
    handleUpdateClick(row) {
      this.updateDialogVisible = true;
      const temp = Object.assign({}, row); // 复制当前行数据，创建一个新的对象
      this.newMerchantSort = temp;
    },
    //删除点击操作
    handleDeleteClick(row) {
      this.$confirm(`是否删除编号为"${row.sortId}"的分类？`, "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delete(row);
        })
        .catch(() => {
          console.log("取消删除");
        });
    },

    // -----------------------分页区域-----------------------
    //切换记录数
    handleSizeChange(val) {
      this.pages.size = val;
      // 检查是否为空字符串
      const isEmptyString = (str) => str === "";
      const hasNonEmptyString = !isEmptyString(this.merchantSort.sortName);
      // 根据检查结果返回对应的值
      if (!hasNonEmptyString) {
        // 没有数据
        this.getPageList();
      } else {
        // 至少有一个属性有值
        this.onSearchFrom();
      }
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pages.current = val;
      // 检查是否为空字符串
      const isEmptyString = (str) => str === "";
      const hasNonEmptyString = !isEmptyString(this.merchantSort.sortName);
      // 根据检查结果返回对应的值
      if (!hasNonEmptyString) {
        // 没有数据
        this.getPageList();
      } else {
        // 至少有一个属性有值
        this.onSearchFrom();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-form-item {
  font-weight: 700;
  color: #606266;
}
.merchant-sort-search .el-input,
.merchant-sort-search .el-select {
  width: 240px;
}
.el-input-number {
  width: 190px;
}
.merchant-sort-btn {
  margin-bottom: 8px;
}
.el-pagination {
  margin-top: 15px;
}
</style>