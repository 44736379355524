import Vue from 'vue';
import Vuex from 'vuex';

// 挂在Vuex
Vue.use(Vuex);

// 创建Vuex对象
const store = new Vuex.Store({
    state: {
        username: 'admin',
    },
    mutations: {
        setUsername(state, username) {
            state.username = username;
        }
    }
})

export default store
