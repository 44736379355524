<template>
  <div class="aside-container">
    <div class="title">管理系统</div>
    <el-menu
      default-active="1-4-1"
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
    >
      <router-link to="/home">
        <el-menu-item index="1">
          <i class="el-icon-menu"></i>
          <span slot="title">首页</span>
        </el-menu-item>
      </router-link>

      <!-- 遍历渲染菜单列表 -->
      <el-submenu
        :index="index1 + ''"
        v-for="(item, index1) in menuList"
        :key="item.menuId"
      >
        <template slot="title">
          <i :class="item.icon"></i>
          <span slot="title">{{ item.menuName }}</span>
        </template>

        <el-menu-item-group>
          <router-link
            :to="'/home' + subItem.menuUrl"
            v-for="(subItem, index2) in item.subMenu"
            :key="subItem.menuId"
          >
            <el-menu-item :index="index1 + '-' + index2"
              ><i :class="subItem.icon"></i> {{ subItem.menuName }}</el-menu-item
            >
          </router-link>
        </el-menu-item-group>
      </el-submenu>
      <!-- <el-menu-item index="4">
        <i class="el-icon-setting"></i>
        <span slot="title">121</span>
      </el-menu-item> -->
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: false,
      menuList: [],
    };
  },
  created() {
    this.getMenuList();
  },
  
  methods: {
    //获取菜单列表
    async getMenuList() {
      const { data: res } = await this.$axios.get(`${this.$http}/home/menu`);
      this.menuList = res.data;
    },
  },
};
</script>

<style lang="less" scoped>
.aside-container {
  height: 100vh;
  background-color: #ffffff;
}
.title {
  box-sizing: border-box;
  border: 2px solid #fafafa;
  color: #5c5959;
  font-size: 20px;
  text-align: center;
  height: 60px;
  line-height: 60px;
}
a {
  text-decoration: none;
  color: #5c5959;
}
</style>