import { render, staticRenderFns } from "./Aside.vue?vue&type=template&id=37b187eb&scoped=true"
import script from "./Aside.vue?vue&type=script&lang=js"
export * from "./Aside.vue?vue&type=script&lang=js"
import style0 from "./Aside.vue?vue&type=style&index=0&id=37b187eb&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37b187eb",
  null
  
)

export default component.exports