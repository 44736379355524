<template>
  <div class="index-container">
    <!--页面主要内容-->
    <div class="row">
      <div class="item-message">
        <div class="item-img">
          <img :src="require('@/assets/images/1.png')" class="icon" />
        </div>
        <span>102,125.00</span>
      </div>
      <div class="item-name">今日收入</div>
    </div>

    <div class="row">
      <div class="item-message">
        <div class="item-img">
          <img :src="require('@/assets/images/2.png')" class="icon" />
        </div>
        <span>102,125.00</span>
      </div>
      <div class="item-name">今日收入</div>
    </div>

    <div class="row">
      <div class="item-message">
        <div class="item-img">
          <img :src="require('@/assets/images/3.png')" class="icon" />
        </div>
        <span>102,125.00</span>
      </div>
      <div class="item-name">今日收入</div>
    </div>

    <div class="row">
      <div class="item-message">
        <div class="item-img">
          <img :src="require('@/assets/images/4.png')" class="icon" />
        </div>
        <span>102,125.00</span>
      </div>
      <div class="item-name">今日收入</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataList: [
        {
          id: 1,
          message: "102,125.00",
          dataname: "今日收入",
          backgroundColor: "#007bff",
        },
        {
          id: 2,
          icon: "",
          message: "920,000",
          dataname: "用户总数",
          backgroundColor: "#F44236",
        },
        {
          id: 3,
          icon: "",
          message: "34,005,000",
          dataname: "下载总量",
          backgroundColor: "#15C377",
        },
        {
          id: 4,
          icon: "",
          message: "153 条",
          dataname: "新增留言",
          backgroundColor: "#926DDE",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.index-container {
  width: 100%;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.row:nth-child(1) {
  background-color: #007bff;
}
.row:nth-child(2) {
  background-color: #F44236;
}
.row:nth-child(3) {
  background-color: #15C377;
}
.row:nth-child(4) {
  background-color: #926DDE;
}
.row {
  width: 340px;
  height: 105px;
  border-radius: 10px;
  color: #fff;
  font-size: 22px;
  box-sizing: border-box;
  padding: 20px;
}
.item-message{
  display: flex;
      justify-content: space-between;

}
.item-img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon {
  width: 24px;
  height: 24px;
}

.item-name {
  float: right;
  font-size: 14px;
}
</style>