<template>
  <div class="test-container">
    <!-- <el-input type="text" v-model="captchaCode" placeholder="请输入验证码" />
    <img :src="captchaImage" alt="" /> -->
  </div>
</template>
  
<script>
export default {
  components: {},
  data() {
    return {
      captchaCode: "111",
      captchaImage: "",
    };
  },
  created() {
  },
  methods: {
    // 获取验证码
     subCode() {
      this.$axios({
        methods: "post",
        baseURL: `${this.$http}`,
        url: `/test/test`,
        data: {
          captchaCode: this.captchaCode,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    async getCaptcha() {
      // 发送请求给后端，获取验证码图片和相关信息
      const { data: res } = await this.$axios.get(`${this.$http}/login/code`);
      this.captchaImage = res.data;
    },
    // 提交验证请求
    submit() {
      // 发送验证码和用户输入的验证码给后端进行验证
    },
  },
};
</script>
<style lang="stylus" scoped></style>
  