<template>
  <div class="loginMain-container">
    <div class="from">
      <router-view />
    </div>

    <div class="icp">
      <a href="https://beian.miit.gov.cn">蜀ICP备2024087339号</a>
    </div>
  </div>    
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.loginMain-container {
  margin: -8px;
  width: 100%;
  height: 100vh;
  position: fixed;
  background-image: linear-gradient(to right, #fbc2eb, #a6c1ee);
  //   background: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
}

.from {
  width: 358px;
  height: 450px;
  background-color: #fff;
  border-radius: 15px;
  padding: 0 50px;
  margin: 215px auto;
  overflow: hidden;
}
.icp {
  text-align: center;
}
.icp a {
  font-size: 14px;
  color: #fff;
  text-decoration: none;
}
</style>