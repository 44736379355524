<template>
  <div class="user-container">
    <!-- 搜索区域 -->
    <div class="user-search">
      <el-form
        :inline="true"
        :model="user"
        ref="user"
        class="demo-form-inline"
        size="small"
      >
        <el-form-item label="用户名称" prop="username">
          <el-input
            v-model.trim="user.username"
            placeholder="请输入用户名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="phone">
          <el-input
            v-model.trim="user.phone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-select v-model.trim="user.status" placeholder="用户状态">
            <el-option label="正常" value="1"></el-option>
            <el-option label="停用" value="0"></el-option>
          </el-select>
        </el-form-item>
        <br />
        <el-form-item label="创建时间" prop="dateList">
          <el-date-picker
            v-model.trim="user.dateList"
            type="daterange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 240px"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="onSearchFrom"
            >搜索</el-button
          >
          <el-button
            icon="el-icon-search"
            size="mini"
            @click="resetForm('user')"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- 按钮区域 -->
    <div class="user-btn">
      <el-button
        type="primary"
        plain
        size="mini"
        icon="el-icon-plus"
        @click="addDialogVisible = true"
        >新增</el-button
      >
      <el-button
        type="success"
        plain
        size="mini"
        icon="el-icon-edit"
        :disabled="updateDisabled"
        @click="handleupdateDialogVisible"
        >修改</el-button
      >
      <el-button
        type="danger"
        plain
        size="mini"
        icon="el-icon-delete"
        :disabled="deleteDisabled"
        @click="handleBatchDeletion"
        >删除</el-button
      >
    </div>

    <!-- 新增弹窗内容 -->
    <el-dialog
      title="添加用户"
      append-to-body
      width="38%"
      :visible.sync="addDialogVisible"
      :before-close="addHandleClose"
      class="add-dialog"
    >
      <el-form
        :inline="true"
        label-width="80px"
        :model="newUser"
        :rules="rules"
        ref="newUser"
        size="small"
        class="demo-form-inline"
      >
        <el-form-item label="用户名称" prop="username">
          <el-input
            v-model.trim="newUser.username"
            placeholder="请输入用户名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="用户密码" prop="password">
          <el-input
            v-model.trim="newUser.password"
            placeholder="请输入用户密码"
          ></el-input>
        </el-form-item>

        <el-form-item label="用户昵称" prop="nickname">
          <el-input
            v-model.trim="newUser.nickname"
            placeholder="请输入用户昵称"
          ></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="phone">
          <el-input
            v-model.trim="newUser.phone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>

        <el-form-item label="邮箱" prop="email">
          <el-input
            v-model.trim="newUser.email"
            placeholder="请输入邮箱"
          ></el-input>
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <template>
            <el-radio v-model="newUser.status" :label="1">正常</el-radio>
            <el-radio v-model="newUser.status" :label="0">禁用</el-radio>
          </template>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addUser('newUser')">确 定</el-button>
        <el-button @click="addCancel">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 修改弹窗内容 -->
    <el-dialog
      title="修改用户"
      append-to-body
      width="38%"
      :visible.sync="updateDialogVisible"
      :before-close="updateHandleClose"
      class="add-dialog"
    >
      <el-form
        :inline="true"
        label-width="80px"
        :model="newUser"
        :rules="rules"
        ref="newUser"
        size="small"
        class="demo-form-inline"
      >
        <el-form-item label="用户名称" prop="username">
          <el-input
            v-model.trim="newUser.username"
            placeholder="请输入用户名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="用户密码" prop="password">
          <el-input
            v-model.trim="newUser.password"
            placeholder="请输入用户密码"
          ></el-input>
        </el-form-item>

        <el-form-item label="用户昵称" prop="nickname">
          <el-input
            v-model.trim="newUser.nickname"
            placeholder="请输入用户昵称"
          ></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="phone">
          <el-input
            v-model.trim="newUser.phone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>

        <el-form-item label="邮箱" prop="email">
          <el-input
            v-model.trim="newUser.email"
            placeholder="请输入邮箱"
          ></el-input>
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <template>
            <el-radio v-model="newUser.status" :label="1">正常</el-radio>
            <el-radio v-model="newUser.status" :label="0">禁用</el-radio>
          </template>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="updateUser('newUser')"
          >确 定</el-button
        >
        <el-button @click="updateCancel">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 表格区域 -->
    <el-table
      :data="userList"
      style="width: 100%"
      :header-cell-style="headerRowStyle"
      v-loading="loading"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="userId"
        label="用户编号"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="username"
        label="用户名"
        width="180"
      ></el-table-column>
      <el-table-column prop="nickname" label="用户昵称"></el-table-column>
      <el-table-column prop="phone" label="手机号"></el-table-column>

      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.status"
            :active-value="1"
            :inactive-value="0"
            @change="handleSwitchChange($event, scope.row)"
          >
          </el-switch> </template
      ></el-table-column>

      <el-table-column
        prop="createTime"
        label="创建时间"
        :formatter="formatDate"
      ></el-table-column>

      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope" v-if="scope.row.username !== 'admin'">
          <el-button
            @click="handleUpdateClick(scope.row)"
            type="text"
            size="small"
            icon="el-icon-edit"
            >修改</el-button
          >
          <el-button
            type="text"
            size="small"
            icon="el-icon-delete"
            @click="handleDeleteClick(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页区域 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pages.current"
      :page-sizes="[3, 5, 10]"
      :page-size="5"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pages.total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //表格表头样式
      headerRowStyle: {
        color: " #515A6E",
        backgroundColor: " #f8f8f9",
      },

      //----------搜索区域-----------
      //搜索用户数据源
      user: {
        username: "",
        phone: "",
        status: "",
        dateList: [],
      },

      //---------增删改按钮区域---------
      radio: "1",
      //修改按钮禁用状态
      updateDisabled: true,
      //删除按钮禁用状态
      deleteDisabled: true,
      //新增弹窗
      addDialogVisible: false,
      //修改弹窗
      updateDialogVisible: false,
      //新增用户数据源
      newUser: {
        username: "",
        phone: "",
        status: 1,
      },
      //非空校验
      rules: {
        username: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
      },
      // ----------表格区域------------
      //列表加载效果
      loading: false,
      //用户数据列表
      userList: [],
      //多选框数据列表
      multipleSelection: [],

      //----------分页区域------------
      pages: {
        current: 1, //当前页
        size: 5, //每页记录数
      },
    };
  },

  created() {
    this.getUserPageList();
  },

  methods: {
    // -----------------------搜索区域-----------------------
    //搜索查询用户列表
    async onSearchFrom() {
      //将数据封装为一个对象
      const requestData = {
        user: this.user,
        pageUtils: this.pages,
      };
      this.loading = true;
      const { data: res } = await this.$axios.post(
        `${this.$http}/user/search`,
        requestData
      );
      //设置加载效果定时器
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      this.userList = res.data.userList;
      this.pages = res.data.pageUtils;
    },
    //重置搜索
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // -----------------------按钮区域-----------------------
    //修改按钮点击弹出框
    handleupdateDialogVisible() {
      this.updateDialogVisible = true;
      const tempUser = Object.assign({}, this.multipleSelection[0]); // 复制当前行数据，创建一个新的对象
      this.newUser = tempUser;
    },
    // 新增弹窗确认按钮点击
    addUser(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 验证成功，提交表单
          this.addData();
          if (this.$refs["newUser"]) {
            this.$refs.newUser.resetFields();
            this.newUser = {};
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //新增弹窗取消按钮点击
    addCancel() {
      this.addDialogVisible = false;
      if (this.$refs["newUser"]) {
        this.$refs.newUser.resetFields();
        this.newUser = {};
      }
      // if (this.$refs["newUser"] !== undefined) {
      //   this.$refs.newUser.resetFields();
      // }
      // //清理input数据
      // for (let key in this.form) {
      //   this.form[key] = "";
      // }
    },
    //新增点击右上角x
    addHandleClose(done) {
      this.addDialogVisible = false;
      if (this.$refs["newUser"]) {
        this.$refs.newUser.resetFields();
        this.newUser = {};
      }
    },
    //修改弹窗确认按钮点击
    updateUser(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 验证成功，提交表单
          this.updateData();
          if (this.$refs["newUser"]) {
            this.$refs.newUser.resetFields();
            this.newUser = {};
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //修改弹窗取消按钮点击
    updateCancel() {
      this.updateDialogVisible = false;
      if (this.$refs["newUser"]) {
        console.log(11);
        this.$refs.newUser.resetFields();
        this.newUser = {};
      }
    },
    //修改点击右上角x
    updateHandleClose(done) {
      this.updateDialogVisible = false;
      if (this.$refs["newUser"]) {
        this.$refs.newUser.resetFields();
        this.newUser = {};
      }
    },
    //删除按钮点击
    handleBatchDeletion() {
      if (this.multipleSelection.length == 1) {
        this.handleDeleteClick(this.multipleSelection[0]);
        // this.individualDeletion(this.multipleSelection[0]);
      } else {
        let messageUid = "";
        for (let i = 0; i < this.multipleSelection.length; i++) {
          messageUid += `"${this.multipleSelection[i].userId}"`;
        }
        this.$confirm(`是否删除用户编号为${messageUid}的用户？`, "系统提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.multipleDeletion();
          })
          .catch(() => {
            console.log("取消删除");
          });
      }
    },
    // -----------------------增删改区域-----------------------
    //新增数据
    async addData() {
      const { data: res } = await this.$axios.post(
        `${this.$http}/user/add`,
        this.newUser
      );
      if (res.data) {
        //添加成功
        this.$message({
          message: res.message,
          type: "success",
        });
        //添加成功关闭弹窗
        this.addDialogVisible = false;
        //添加成功刷新表格
        this.getUserPageList();
      } else {
        this.$message.error(res.message);
      }
    },
    //修改数据
    async updateData() {
      const { data: res } = await this.$axios.post(
        `${this.$http}/user/update`,
        this.newUser
      );
      if (res.data) {
        //修改成功
        this.$message({
          message: res.message,
          type: "success",
        });
        //修改成功关闭弹窗
        this.updateDialogVisible = false;
        //修改成功刷新表格
        this.getUserPageList();
      } else {
        this.$message.error(res.message);
      }
    },
    //单行删除
    async individualDeletion(row) {
      const { data: res } = await this.$axios.post(
        `${this.$http}/user/delete`,
        row
      );
      if (res.data) {
        this.$message({
          message: res.message,
          type: "success",
        });
        this.getUserPageList();
      }
    },
    //多行删除
    async multipleDeletion() {
      const { data: res } = await this.$axios.post(
        `${this.$http}/user/batchDelete`,
        this.multipleSelection
      );
      if (res.data) {
        this.$message({
          message: res.message,
          type: "success",
        });
        this.getUserPageList();
      }
    },
    // -----------------------表格区域-----------------------
    //日期格式化
    formatDate(row, column, cellValue) {
      const date = new Date(cellValue);
      function padZero(num) {
        return num < 10 ? "0" + num : num;
      }
      const year = date.getFullYear();
      const month = padZero(date.getMonth() + 1);
      const day = padZero(date.getDate());
      const hours = padZero(date.getHours());
      const minutes = padZero(date.getMinutes());
      const seconds = padZero(date.getSeconds());
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    //获取用户列表
    async getUserPageList() {
      this.loading = true;
      const { data: res } = await this.$axios.post(
        `${this.$http}/user/list`,
        this.pages
      );
      //设置加载效果定时器
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      this.userList = res.data.userList;
      this.pages = res.data.pageUtils;
    },
    //多选操作
    handleSelectionChange(val) {
      this.updateDisabled = val.length !== 1;
      this.deleteDisabled = val.length === 0;
      this.multipleSelection = val;
      // console.log(this.multipleSelection);
    },
    //切换状态效果
    handleSwitchChange(val, row) {
      this.$confirm(`确认要停用"${row.username}"用户吗?`, "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.updateUserStatus(row);
          this.$message({
            type: "success",
            message: "修改成功!",
          });
        })
        .catch(() => {
          if (row.status == 1) {
            row.status = 0;
          } else {
            row.status = 1;
          }
        });
    },
    //修改用户状态
    async updateUserStatus(user) {
      const { data: res } = await this.$axios.post(
        `${this.$http}/user/updateStatus`,
        user
      );
    },
    //修改点击弹窗操作
    handleUpdateClick(row) {
      this.updateDialogVisible = true;
      const tempUser = Object.assign({}, row); // 复制当前行数据，创建一个新的对象
      this.newUser = tempUser;
    },
    //删除点击操作
    handleDeleteClick(row) {
      this.$confirm(`是否删除用户编号为"${row.userId}"的用户？`, "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.individualDeletion(row);
        })
        .catch(() => {
          console.log("取消删除");
        });
    },

    // -----------------------分页区域-----------------------
    //切换记录数
    handleSizeChange(val) {
      this.pages.size = val;
      // 检查属性是否为空字符串
      const isEmptyString = (str) => str === "";
      const hasNonEmptyString =
        !isEmptyString(this.user.username) ||
        !isEmptyString(this.user.phone) ||
        !isEmptyString(this.user.status);
      // 检查 dateList 数组是否为空
      const hasNonEmptyArray =
        Array.isArray(this.user.dateList) && this.user.dateList.length > 0;
      // 根据检查结果返回对应的值
      if (!hasNonEmptyString && !hasNonEmptyArray) {
        // 没有数据
        this.getUserPageList();
      } else {
        // 至少有一个属性有值
        this.onSearchFrom();
      }
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pages.current = val;
      // 检查属性是否为空字符串
      const isEmptyString = (str) => str === "";
      const hasNonEmptyString =
        !isEmptyString(this.user.username) ||
        !isEmptyString(this.user.phone) ||
        !isEmptyString(this.user.status);
      // 检查 dateList 数组是否为空
      const hasNonEmptyArray =
        Array.isArray(this.user.dateList) && this.user.dateList.length > 0;
      // 根据检查结果返回对应的值
      if (!hasNonEmptyString && !hasNonEmptyArray) {
        // 没有数据
        this.getUserPageList();
      } else {
        // 至少有一个属性有值
        this.onSearchFrom();
      }
    },
  },
};
</script>

<style lang="less" scoped>
// -------搜索区域---------
.el-form-item {
  font-weight: 700;
  color: #606266;
}
.user-search .el-input,
.user-search .el-select {
  width: 240px;
}
//--------按钮区域--------
.user-btn {
  margin-bottom: 8px;
}
//-------分页区域--------
.el-pagination {
  margin-top: 15px;
}

.add-dialog .el-input {
  width: 200px;
}
</style>

