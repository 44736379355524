<template>
  <div class="register-container">
    <div class="header">注册</div>
    <el-form
      :model="user"
      status-icon
      :rules="rules"
      ref="user"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="账号" prop="username">
        <el-input v-model="user.username" placeholder="请输入账号"></el-input>
      </el-form-item>

      <el-form-item label="密码" prop="password">
        <el-input
          type="password"
          v-model="user.password"
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPassword">
        <el-input
          type="password"
          v-model="user.checkPassword"
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <router-link to="/">返回登录</router-link>
        <el-button
          type="primary"
          @click="submitForm('user')"
          class="btn-register"
          >注册</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        username: "",
        password: "",
        checkPassword: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        checkPassword: [
          { validator: this.validateConfirmPassword, trigger: "blur" },
        ],
      },
    };
  },

  methods: {
    // 确认密码校验
    validateConfirmPassword(rule, value, callback) {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.user.password) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    },
    // 注册
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 验证成功，提交表单
          console.log(valid);
          this.$axios
            .post(`${this.$http}/login/register`, this.user)
            .then((res) => {
              console.log(res.data);
              if (res.data.code == 200) {
                this.$message({
                  message: res.data.message,
                  type: "success",
                });
                //注册成功跳转登录页面
                this.$router.push("/");
              } else {
                this.$message.error(res.data.message);
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  font-size: 38px;
  font-weight: bold;
  text-align: center;
  line-height: 150px;
}
.el-form {
  margin-top: -10px;
  padding: 20px;
}
.el-form-item {
  position: relative;
  margin-left: -55px;
}

a {
  position: absolute;
  text-decoration-line: none;
  color: #abc1ee;
  right: 0;
  top: -25px;
}
.btn-register {
  background-image: linear-gradient(to right, #a6c1ee, #fbc2eb);
  color: #fff;
  margin-top: 15px;
  width: 100%;
  padding: 10px;
}
</style>